<template>
  <div :class="rootClass">
    <div
      :class="{ 'm-search__searchIcon': true, 'is-visible': !searchActive }"
      @click="toggleSearch"
    >
      <div class="m-search__searchIconText">Suche</div>
      <SearchIcon />
    </div>
    <div
      :class="{ 'm-search__searchCloseIcon': true, 'is-visible': searchActive }"
      @click="toggleSearch"
    >
      <SearchCloseIcon />
    </div>
    <div ref="search" :class="{ 'm-search__searchBar': true, 'is-visible': searchActive }">
      <input
        id="search"
        ref="searchInput"
        v-model="searchString"
        type="text"
        placeholder="Wonach suchen Sie?"
        name="search"
        @input="debouncedSearch"
        @keyup.enter="focusInput"
      />
    </div>

    <div v-show="showSubmenu" ref="searchResultsEl" :class="`${cn}__searchResults`">
      <div :class="`${cn}__section`">
        <div :class="`${cn}__row`">
          <div :class="`${cn}__content`">
            <h1>
              Suchergebnisse für
              <span v-if="searchString" :class="`${cn}__color`">{{ searchString }}</span
              ><span v-else>...</span>
            </h1>
            <div
              v-if="searchString"
              ref="tabbarEl"
              :class="`${cn}__tabs ${tabbarScrollable ? 'is-scrollable' : ''}`"
              @scroll="isTabbarScrollable"
            >
              <div
                v-for="(algoliaIndex, i) in algoliaIndices"
                :key="i"
                :class="{
                  'm-search__tab': true,
                  'is-active': activeTab === algoliaIndex,
                  'has-results': searchResults[i] && searchResults[i].nbHits > 0,
                }"
                @click="
                  () => {
                    if (searchResults[i] && searchResults[i].nbHits > 0) switchTab(algoliaIndex);
                  }
                "
              >
                {{ indexNames[i] }}
                <span :class="`${cn}__resultCount`"
                  >({{ searchResults[i] ? searchResults[i].nbHits : 0 }})</span
                >
              </div>
            </div>
            <ResultList
              v-for="(result, i) in searchResults"
              v-show="activeTab === algoliaIndices[i]"
              :key="result.index"
              :class="`${cn}__pagesTab ${cn}__pagesTab--${algoliaIndices[i]}`"
              :active-tab="activeTab"
              :indices-name="algoliaIndices[i]"
              :hits="searchResults[i] ? searchResults[i].hits : []"
            >
              <div v-if="searchResults[i].nbPages > 1" class="m-pagination space-x-4">
                <ul class="m-pagination__inner">
                  <li
                    v-if="pages.currentPageNumber !== 1"
                    class="m-pagination__link m-pagination__link--prev"
                    @click="paginate(i, pages.prevPageNumber || 1)"
                  ></li>
                  <li
                    class="m-pagination__link m-pagination__link--number cursor-pointer"
                    :class="{ 'm-pagination__link--current': pages.currentPageNumber === 1 }"
                    @click="paginate(i, 1)"
                  >
                    1
                  </li>
                  <li
                    v-if="pages.currentPageNumber > pages.pageLimit"
                    class="m-pagination__link m-pagination__link--number disabled m-pagination__link m-pagination__link--breakview"
                  >
                    ...
                  </li>
                  <li
                    v-if="pages.prevPrevPageNumber"
                    class="m-pagination__link m-pagination__link--number cursor-pointer"
                    @click="paginate(i, pages.prevPrevPageNumber)"
                  >
                    {{ pages.prevPrevPageNumber }}
                  </li>
                  <li
                    v-if="pages.prevPageNumber"
                    class="m-pagination__link m-pagination__link--number cursor-pointer"
                    @click="paginate(i, pages.prevPageNumber)"
                  >
                    {{ pages.prevPageNumber }}
                  </li>
                  <li
                    v-if="
                      pages.currentPageNumber !== 1 && pages.currentPageNumber !== pages.lastPage
                    "
                    class="m-pagination__link m-pagination__link--number m-pagination__link--current"
                  >
                    {{ pages.currentPageNumber }}
                  </li>
                  <li
                    v-if="pages.nextPageNumber"
                    class="m-pagination__link m-pagination__link--number cursor-pointer"
                    @click="paginate(i, pages.nextPageNumber)"
                  >
                    {{ pages.nextPageNumber }}
                  </li>
                  <li
                    v-if="pages.nextNextPageNumber"
                    class="m-pagination__link m-pagination__link--number cursor-pointer"
                    @click="paginate(i, pages.nextNextPageNumber)"
                  >
                    {{ pages.nextNextPageNumber }}
                  </li>
                  <li
                    v-if="pages.currentPageNumber <= pages.lastPage - pages.pageLimit"
                    class="m-pagination__link m-pagination__link--number disabled m-pagination__link m-pagination__link--breakview"
                  >
                    ...
                  </li>
                  <li
                    class="m-pagination__link m-pagination__link--number cursor-pointer"
                    :class="{
                      'm-pagination__link--current': pages.currentPageNumber === pages.lastPage,
                    }"
                    @click="paginate(i, pages.lastPage)"
                  >
                    {{ pages.lastPage }}
                  </li>
                  <li
                    v-if="pages.currentPageNumber !== pages.lastPage"
                    class="m-pagination__link m-pagination__link--next"
                    @click="paginate(i, pages.nextPageNumber || pages.lastPage)"
                  ></li>
                </ul>
              </div>
            </ResultList>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { debounce } from 'lodash-es';
import { algoliasearch } from 'algoliasearch';
import ResultList from './__resultList.vue';
import SearchIcon from './__searchIcon.vue';
import SearchCloseIcon from './__searchCloseIcon.vue';
import useRootClass from '../../../src/composables/useRootClass';
import { computed, nextTick, ref, watch } from 'vue';

interface ISearchProps {
  modifiers?: string[];
}

const props = withDefaults(defineProps<ISearchProps>(), {
  modifiers: () => [],
});

const cn = 'm-search';
const { rootClass } = useRootClass({ cn, modifiers: props.modifiers });
const algolia = {
  applicationId: 'VDFMPKLPFH',
  apiKey: '970f902abb7dae50b27449f6415587fb',
};

const env = environment; // eslint-disable-line

const indexNames = ['Inhalte', 'Nachrichten', 'Domladen', 'Termine', 'Kontakte'];

const searchString = ref('');
const algoliaSettings = {
  hitsPerPage: 10,
  attributesToSnippet: ['*:40'],
  snippetEllipsisText: '...',
};
const algoliaIndices = [
  `main_${env}`,
  `news_${env}`,
  `products_${env}`,
  `events_${env}`,
  `team_${env}`,
];

const algoliaQueries = computed(() => {
  const queries = [];

  if (searchString.value) {
    algoliaIndices.forEach((index) => {
      const query = {
        indexName: index,
        query: searchString.value,
        ...algoliaSettings,
      };

      queries.push(query);
    });
  }
  return queries;
});

const algoliaClient = algoliasearch(algolia.applicationId, algolia.apiKey);
const searchResults = ref([]);
const debouncedSearch = debounce(async () => {
  const response = await algoliaClient.search({
    requests: algoliaQueries.value,
  });
  searchResults.value = response.results;
}, 400);

const pages = computed(() => {
  const activeIndex = searchResults.value.find((result) => result.index === activeTab.value);
  const currentPage = activeIndex.page + 1;
  const lastPage = activeIndex.nbPages;
  const pageLimit = 3;

  const prevPage = currentPage - 1;
  const nextPage = currentPage + 1;
  const prevPageNumber = prevPage > 1 ? prevPage : null; //?
  const nextPageNumber = nextPage < lastPage ? nextPage : null; //?;
  const currentPageNumber = currentPage; //?
  return {
    pageLimit,
    lastPage,
    prevPageNumber,
    nextPageNumber,
    currentPageNumber,
    nextNextPageNumber:
      nextPageNumber && nextPageNumber + 1 < lastPage && nextPageNumber === 1 + 1
        ? nextPageNumber + 1
        : null,
    prevPrevPageNumber:
      prevPageNumber && prevPageNumber - 1 > 1 && prevPageNumber === lastPage - 1
        ? prevPageNumber - 1
        : null,
  };
});

const activeTab = ref(`main_${env}`);
function switchTab(tabName) {
  activeTab.value = tabName;
}

const searchInput = ref<HTMLInputElement>(null);
function focusInput(state) {
  if (searchInput.value) {
    if (state === true) {
      nextTick(() => searchInput.value.focus());
    } else {
      nextTick(() => searchInput.value.blur());
    }
  }
}

const showSubmenu = ref(false);

function toggleSubmenu() {
  showSubmenu.value = !showSubmenu.value;
}

function toggleMainMenu(state) {
  document.querySelector('.m-navMain__list').classList.toggle('m-navMain__list--hidden');
  document
    .querySelector('.m-navMain__headList--mobile1')
    .classList.toggle('m-navMain__headList--hiddenMobile');
  document
    .querySelector('.m-navMain__headList--mobile2')
    .classList.toggle('m-navMain__headList--hiddenMobile');

  if (state === true) {
    document.querySelector('.m-navMain__burgerIcon').classList.remove('is-visible');
    document.querySelector('.m-navMain__closeIcon').classList.remove('is-visible');
    document.querySelector('.m-navMain__list--level-1').classList.remove('is-visible');
  } else {
    document.querySelector('.m-navMain__burgerIcon').classList.add('is-visible');
    document.querySelector('html').classList.remove('is-locked');
    document.querySelector('body').classList.remove('is-locked');
  }
}

function lockBody(state) {
  if (state === true) {
    document.querySelector('html').classList.add('is-locked');
    document.querySelector('body').classList.add('is-locked');
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  } else {
    document.querySelector('body').classList.remove('is-locked');
  }
}

const tabbarEl = ref<HTMLElement>(null);

const searchResultsEl = ref<HTMLElement>(null);
async function paginate(searchIndex, pageIndex) {
  if (pageIndex === pages.value.currentPageNumber) return;

  const query = {
    indexName: algoliaIndices[searchIndex],
    query: searchString.value,
    params: {
      ...algoliaSettings,
      page: pageIndex - 1, // Algolia uses zero-based indexing
    },
  };

  try {
    const { results } = await algoliaClient.search([query]);

    if (results?.length) {
      searchResults.value.splice(searchIndex, 1, results[0]);
      searchResultsEl.value.scrollTop = 0;
    }
  } catch (error) {
    console.error('Algolia pagination error:', error);
  }
}

const searchActive = ref(false);
function toggleSearch() {
  searchActive.value = !searchActive.value;
}

const tabbarScrollable = ref(true);
function isTabbarScrollable() {
  if (tabbarEl.value) {
    const maxScrollLeft = tabbarEl.value.scrollWidth - tabbarEl.value.clientWidth;
    tabbarScrollable.value = Math.ceil(tabbarEl.value.scrollLeft) < maxScrollLeft;
  }
}

const resultCount = ref(0);
watch(searchResults, (state) => {
  resultCount.value = searchResults.value
    ?.map((resultSet) => resultSet.nbHits)
    .reduce((a, b) => a + b, 0);
});

watch(resultCount, (state) => {
  nextTick(() => isTabbarScrollable());
});

watch(searchActive, (state) => {
  lockBody(state);
  toggleMainMenu(state);
  focusInput(state);
  toggleSubmenu();

  if (!state) {
    searchResults.value = [];
    searchString.value = '';
  }
});
</script>
