/*
 * Description of what this file is for
 *
 * @package  craft3
 * @author David Hellmann [david@hellmann.io]
 * dynamicForm
 */

import debounce from 'lodash/debounce';
import axios from 'axios';
import handleFormErrors from '../../jsHelper/formErrors';
import getAncestorWith from '../../jsHelper/getAncestorWith';
import datepickerFreeform from './../../_atoms/datepickerFreeform/_script';
import input from './../../_atoms/input/_script';

const dynamicForm = {
  cfg: {
    name: 'freeform',
    selectors: {
      container: '.o-freeform',
      wrapper: '.o-freeform__wrapper',
      formElement: '.o-freeform form',
      submitButtons: '.o-freeform form button[type="submit"]',
      successBanner: '.o-freeform__successBanner',
    },
    classes: {
      hidden: 'is-hidden',
      loading: 'is-loading',
    },
    el: {
      container: undefined,
      wrapper: undefined,
      formElement: undefined,
    },
    els: {
      submitButtons: undefined,
    },
  },

  setElements() {
    this.cfg.el.container = document.querySelector(this.cfg.selectors.container);
    this.cfg.el.wrapper = document.querySelector(this.cfg.selectors.wrapper);
    this.cfg.el.formElement = document.querySelector(this.cfg.selectors.formElement);
    this.cfg.els.submitButtons = document.querySelectorAll(this.cfg.selectors.submitButtons);
  },

  appendSuccessBanner() {
    const bannerRendered = document.querySelector('.o-freeform__successBanner');

    if (!bannerRendered) {
      const successMessage = document.createElement('div');
      successMessage.classList.add('o-freeform__successBanner');
      successMessage.appendChild(document.createTextNode('Formular erfolgreich abgesendet.'));

      if (this.cfg.el.wrapper.childNodes[0]) {
        this.cfg.el.wrapper.insertBefore(successMessage, this.cfg.el.wrapper.childNodes[0]);
      }
    }
  },

  appendFailureBanner() {
    const successBanner = document.querySelector('.o-freeform__successBanner');
    if (successBanner) {
      successBanner.remove();
    }

    const bannerRendered = document.querySelector('.o-freeform__failureBanner');

    if (!bannerRendered) {
      const failureMessage = document.createElement('div');
      failureMessage.classList.add('o-freeform__failureBanner');
      failureMessage.appendChild(document.createTextNode('Es ist ein Fehler aufgetreten.'));

      if (this.cfg.el.wrapper.childNodes[0]) {
        this.cfg.el.wrapper.insertBefore(failureMessage, this.cfg.el.wrapper.childNodes[0]);
      }
    }
  },

  initForm() {
    this.generateNewCsrfToken(this.cfg.el.formElement);
    this.generateNewHoneypotValue(this.cfg.el.formElement);

    // don't show success banner
    this.cfg.el.formElement.freeform.setOption('renderSuccess', () => {
      //
    });

    // don't show general error message for form
    this.cfg.el.formElement.freeform.setOption('renderFormErrors', () => {
      //
    });

    // render individual field errors (atom formError)
    this.cfg.el.formElement.freeform.setOption('renderFieldErrors', (errors) => {
      handleFormErrors(this.cfg.el.formElement, errors);
    });

    // Override the way those messages are removed
    this.cfg.el.formElement.freeform.setOption('removeMessages', () => {
      const errors = this.cfg.el.formElement.querySelectorAll('.a-formError');
      if (errors.length > 0) {
        [...errors].forEach((error) => error.remove());
      }
    });

    // check if redirect from submitted
    const formSubmitted = window.location.href.indexOf('?submitted') !== -1;
    if (formSubmitted) {
      this.appendSuccessBanner();
    }

    const responseTemplate = this.cfg.el.container.dataset.ajaxResponse;
    console.log(responseTemplate);
    if (responseTemplate) {
      this.cfg.el.formElement.addEventListener('freeform-ajax-success', async () => {
        // eslint-disable-line
        this.hideFormAndContent();
        this.endSubmitWithSuccess();
        const response = await fetch(responseTemplate);
        const template = await response.text();
        this.showResponse(template);
        this.sendSuccessEvent();
        window.setTimeout(() => this.showFormAndContent(), 1000);
      });
    } else {
      this.cfg.el.formElement.addEventListener('freeform-ajax-success', (event) => {
        if (event.response.returnUrl === window.location.pathname) {
          window.location.href = `${event.response.returnUrl}?submitted`;
        } else {
          window.location.href = event.response.returnUrl;
        }
      });
    }

    this.cfg.el.formElement.addEventListener('freeform-ajax-error', () => {
      this.endSubmitWithFailure();
    });
  },

  initEventListeners() {
    this.cfg.el.formElement.addEventListener('submit', () => {
      this.startSubmit();
    });

    /**
     * Currently we can not use an import for this constant, as it will break our pipelines in some cases.
     * @see https://github.com/solspace/craft-freeform/blob/v5/packages/scripts/src/lib/plugin/constants/event-types.ts
     */
    this.cfg.el.formElement.addEventListener('freeform-field-table-after-row-added', (event) => {
      const inputElements = event.row.querySelectorAll('input');
      const labels = event.row.querySelectorAll('label');

      /**
       * This is a small workaround for a possible bug in Freeform:
       * When cloning rows, the checkbox value is not set,
       * therefore Freeform doesn't register checked submissions correctly.
       */
      inputElements.forEach((input) => {
        input.id = this._incrementTableFieldIdOrName(input.id);
        if (input.type === 'checkbox') {
          input.value = true;
        }
      });

      labels.forEach((label) => {
        label.setAttribute('for', this._incrementTableFieldIdOrName(label.getAttribute('for')));
      });

      /**
       * We need to remove all status classes which have been assigned by our
       * custom form components. Freeform will just clone them, which marks
       * empty fields as filled & valid, even if they are not, since the
       * classes might have been present on the original element used for cloning.
       */
      const datepickers = event.row.querySelectorAll('.a-datepicker');
      const textInputs = event.row.querySelectorAll('.a-input');
      const elementsToReset = [...datepickers, ...textInputs];
      if (elementsToReset) {
        elementsToReset.forEach((element) => {
          element.classList.remove('is-valid');
          const input = element.querySelector('input');
          if (input) {
            input.removeAttribute('data-js-initialized');
            input.classList.remove('has-content', 'has-focused');
          }
        });
      }

      // Re-Init JS Components after Row Clone
      datepickerFreeform.init();
      input.init();
    });

    [...this.cfg.el.formElement.elements].forEach((field) => {
      field.addEventListener(
        'input',
        debounce((evt) => {
          const { target } = evt;
          switch (target.tagName.toLowerCase()) {
            case 'input': {
              let element;
              if (target.type === 'checkbox') {
                element = getAncestorWith('.a-checkbox', target);
                if (element) {
                  element.classList.remove('a-checkbox--errors');
                }
              } else {
                element = getAncestorWith('.a-input', target);
                if (element) {
                  element.classList.remove('a-input--errors');
                }
              }
              if (element) {
                const errors = element.querySelectorAll('.a-formError');
                if (errors.length > 0) {
                  [...errors].forEach((error) => error.remove());
                }
              }
              break;
            }
            case 'select': {
              const element = getAncestorWith('.a-select', target);
              if (element) {
                element.classList.remove('a-select--errors');
                element.querySelectorAll('.a-formError').remove();
              }
              break;
            }
            case 'textarea': {
              const element = getAncestorWith('.a-textarea', target);
              if (element) {
                element.classList.remove('a-textarea--errors');
                const errors = element.querySelectorAll('.a-formError');
                if (errors) {
                  [...errors].forEach((error) => error.remove());
                }
              }
              break;
            }
            default: {
              break;
            }
          }
        }, 200),
      );
    });
  },

  startSubmit() {
    this.cfg.els.submitButtons.forEach((button) => {
      button.classList.add(this.cfg.classes.loading);
    });
  },

  sendSuccessEvent() {
    const event = document.createEvent('HTMLEvents');
    event.initEvent('formSentSuccessful', true, true);
    event.eventName = 'formSentSuccessful';
    this.cfg.el.formElement.dispatchEvent(event);
  },

  endSubmitWithFailure() {
    this.appendFailureBanner();
    this.cfg.els.submitButtons.forEach((button) => {
      button.classList.remove(this.cfg.classes.loading);
    });
  },

  endSubmitWithSuccess() {
    this.cfg.els.submitButtons.forEach((button) => {
      button.classList.remove(this.cfg.classes.loading);
    });
  },

  hideFormAndContent() {
    this.cfg.el.container.classList.add(this.cfg.classes.hidden);
  },

  showFormAndContent() {
    this.cfg.el.container.classList.remove(this.cfg.classes.hidden);
  },

  showResponse(content) {
    this.cfg.el.container.innerHTML = content;
  },

  generateNewCsrfToken(form) {
    const csrfInput = form.querySelector('input[name="CRAFT_CSRF_TOKEN"]');

    if (csrfInput) {
      axios
        .get('/csrf')
        .then((response) => {
          [csrfInput.value] = response.data.split('<script');
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    }
  },

  generateNewHoneypotValue(form) {
    const honeypotName = form.querySelector('#honeypotName')?.innerHTML;
    const honeypotValue = form.querySelector('#honeypotValue')?.innerHTML;
    const honeypotInput = form.querySelector('input[name^="freeform_form_handle"]');

    if (honeypotValue && honeypotName && honeypotInput) {
      form.dataset.honeypotName = honeypotName;
      form.dataset.honeypotValue = honeypotValue;

      honeypotInput.setAttribute('id', honeypotName);
      honeypotInput.setAttribute('name', honeypotName);
      honeypotInput.value = honeypotValue;
    }
  },

  init() {
    (() => {
      window.addEventListener('load', () => {
        this.setElements();

        if (this.cfg.el.formElement && this.cfg.el.formElement.freeform) {
          this.initEventListeners();
          this.initForm();
        }

        const formSubmitted = window.location.href.indexOf('?submitted') !== -1;
        if (formSubmitted) {
          this.appendSuccessBanner();
        }
      });
    })();
  },

  _incrementTableFieldIdOrName(string) {
    return string.replace(/(\D+)(\d+)]\[(\d+)]/, (match, p1, p2, p3) => {
      const incrementedIndex = parseInt(p2, 10) + 1;
      return `${p1}${incrementedIndex}][${p3}]`;
    });
  },
};

export default dynamicForm;
