<!-- eslint-disable -->
<template>
  <svg class="o-searchChurches__searchIcon"
       width="27px"
       height="27px"
       viewBox="0 0 27 27"
       version="1.1"
       xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Symbols"
       stroke="none"
       stroke-width="1"
       fill="none"
       fill-rule="evenodd">
      <g id="desktop-/-nav-/-normal-/-home" transform="translate(-1236.000000, -105.000000)">
        <g id="icons-/-24px-/-search" transform="translate(1237.000000, 106.000000)">
          <path id="Shape" d="M19,10 C19,5.0376 14.96265,1 10,1 C5.03735,1 1,5.0376 1,10 C1,14.9624 5.03735,19 10,19 C14.96265,19 19,14.9624 19,10 Z" />
          <path id="Shape"
                stroke="#5BC3BE"
                stroke-width="2"
                fill="#5BC3BE"
                fill-rule="nonzero"
                d="M23.9619,23.2549 L17.40445,16.6974 C19.01155,14.9224 20,12.5774 20,10 C20,4.48585 15.5139,0 10,0 C4.4861,0 0,4.48585 0,10 C0,15.51415 4.4861,20 10,20 C12.57735,20 14.92235,19.01155 16.6974,17.40445 L23.2549,23.9619 L23.9619,23.2549 Z M1,10 C1,5.0376 5.03735,1 10,1 C14.96265,1 19,5.0376 19,10 C19,14.9624 14.96265,19 10,19 C5.03735,19 1,14.9624 1,10 Z" />
        </g>
      </g>
    </g>
  </svg>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'SearchIcon',
};
</script>
