<template>
  <div class="flex">
    <div class="relative h-20 w-20 flex-shrink-0 overflow-hidden">
      <div v-if="!image" class="absolute inset-0 rounded bg-gray-200"></div>
      <img
        v-if="image"
        :src="image"
        :alt="title"
        sizes="(min-width: 320px) 128px, 128px"
        class="m-contactPersonItem__imageVue lazyload"
      />
    </div>
    <div class="ml-6">
      <h4 class="my-0 !leading-tight text-sm">{{ title }}</h4>
      <p v-if="subtitle" class="mt-0 mb-0 block opacity-60 text-xs">{{ subtitle }}</p>
      <ul class="!mt-1 text-xs">
        <li v-if="phone">
          Telefon: <a class="underline" :href="`tel:${phoneNumber}`">{{ phone }}</a>
        </li>
        <li v-if="email">
          Mail: <a class="underline" :href="`mailto: ${email}`">{{ email }}</a>
        </li>
        <li v-if="website?.link">
          Web: <a class="underline" :href="website.link">{{ website.text || website.link }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts" setup>
import usePhoneNumber from '../../../src/composables/usePhoneNumber';

interface IWebsite {
  link: string;
  text: string;
}

interface IProps {
  title: string;
  subtitle?: string;
  image?: string;
  phone: string;
  email: string;
  website: IWebsite;
}

const props = defineProps<IProps>();

const phoneNumber = usePhoneNumber(props.phone);
</script>
