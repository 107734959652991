<template>
  <div class="a-audioHtmlPlayer" :class="{ 'is-deactivated': !active }">
    <audio
      v-if="active"
      :id="playerId"
      ref="player"
      :src="src"
      class="a-audioHtmlPlayer__player"
      preload="none"
    />
    <div ref="playBtn" class="a-audioHtmlPlayer__playBtn">
      <div class="a-audioHtmlPlayer__playBtn--icon" />
    </div>
    <div ref="pauseBtn" class="a-audioHtmlPlayer__pauseBtn hidden">
      <div class="a-audioHtmlPlayer__pauseBtn--icon" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AudioPlayer',

  props: {
    modifiers: {
      type: Array,
      default: () => [],
    },
    src: {
      type: String,
      default: null,
    },
    playerId: {
      type: String,
      default: null,
    },
    active: {
      type: Boolean,
      default: () => true,
    },
  },

  data() {
    return {
      cn: 'm-bellSearch',
      item: null,
      overview: null,
      musical: null,
      technical: null,
      inscription: null,
    };
  },

  mounted() {
    if (this.$refs.player) {
      this.$refs.playBtn.addEventListener('click', (event) => {
        event.preventDefault();
        this.$refs.player.play();
        this.$refs.pauseBtn.classList.remove('hidden');
        this.$refs.playBtn.classList.add('hidden');
      });

      this.$refs.pauseBtn.addEventListener('click', (event) => {
        event.preventDefault();
        this.$refs.player.pause();
        this.$refs.player.currentTime = 0;
        this.$refs.playBtn.classList.remove('hidden');
        this.$refs.pauseBtn.classList.add('hidden');
      });
    }
  },
};
</script>
