<template>
  <div>
    <ContactSearch v-if="isContacts" :contacts="hits" />
    <template v-if="!isContacts">
      <div v-for="(hit, index) in hits" :key="index">
        <div v-if="hit.entryType === 'articles'">
          <SearchResult
            :modifiers="[]"
            :headline="hit._highlightResult.title.value"
            :category="hit.category"
            :date="hit.publishingDate ? hit.publishingDate.date : null"
            :teaser-text="getEntryTeaserText(hit)"
            :image-src="hit.entryImageSrc"
            :link="hit.link"
          />
        </div>
        <div v-else-if="hit.entryType === 'pages'">
          <SearchResult
            :modifiers="[]"
            :headline="hit._highlightResult.title.value"
            :category="hit.category"
            :teaser-text="getEntryTeaserText(hit)"
            :link="hit.link"
            :image-src="hit.entryImageSrc"
          />
        </div>
        <div v-else-if="hit.entryType === 'parishesAssociations'">
          <SearchResult
            :modifiers="[]"
            :headline="`Pfarrverband ${hit._highlightResult.title.value}`"
            :teaser-text="getEntryTeaserTextForParishAssociation(hit)"
            :link="hit.link"
            :image-src="hit.image"
          />
        </div>
        <div v-else-if="hit.entryType === 'churches'">
          <SearchResult
            :modifiers="[]"
            :headline="hit._highlightResult.title.value"
            :teaser-text="hit.address"
            :link="hit.link"
            :image-src="hit.image"
          />
        </div>
        <div v-else-if="hit.entryType === 'events'">
          <TeaserDateSitebarItem
            :modifiers="['grey', 'search']"
            :headline="hit.title"
            :label="hit.label"
            :location-town="hit.locationTown"
            :location-title="hit.locationTitle"
            :start-date="hit.startDate ? hit.startDate.date : null"
            :end-date="hit.endDate ? hit.endDate.date : null"
            :start-time="hit.startTime ? hit.startTime.date : null"
            :end-time="hit.endTime ? hit.endTime.date : null"
            :link="hit.link"
          />
        </div>
        <div v-else-if="hit.entryType == 'products'" class="m-searchResult">
          <ProductCard
            :title="hit.title"
            :price="hit.price"
            :subtitle="hit.productSubline"
            :image="hit.image"
            :link="hit.link"
          />
        </div>
      </div>
    </template>
    <slot />
  </div>
</template>

<script setup lang="ts">
import TeaserDateSitebarItem from '../teaserDateSitebarItem/_component.vue';
import SearchResult from '../searchResult/_component.vue';
import ProductCard from '../productCard/_component.vue';
import ContactSearch from '../contactSearch/contactSearch.vue';
import { computed } from 'vue';

interface IResultListProps {
  hits: [];
  activeTab: string;
  indicesName: string;
}

const props = withDefaults(defineProps<IResultListProps>(), {
  hits: () => [],
});

function isFullyMatched(highlightResult) {
  return highlightResult.matchLevel === 'full';
}

function getEntryTeaserText(hit) {
  let text = null;
  const { entryTeaserText } = hit._snippetResult;
  const { content } = hit._snippetResult;

  // check if entry text is a match
  if (entryTeaserText && isFullyMatched(entryTeaserText)) {
    text = entryTeaserText.value;
  } else if (content) {
    // else search first content element that matches
    const contentLength = content.length;
    let index = 0;
    let result = false;
    while (index < contentLength && !result) {
      const contentEntry = Object.values(content[index])[0];
      if (isFullyMatched(contentEntry)) {
        text = contentEntry.value;
        result = true;
      }
      index += 1;
    }
    if (!result) {
      // if no content element matches, use entry text anyway
      text = entryTeaserText ? entryTeaserText.value : null;
    }
  }
  return text;
}

const isContacts = computed(() => {
  return props.indicesName.includes('team');
});

function getEntryTeaserTextForParishAssociation(hit) {
  let text = null;
  const { entryIntroText } = hit._snippetResult;
  const { locationDescription } = hit._snippetResult;
  const { churchesAndParishes } = hit._snippetResult;

  // Check if entry text is a match
  if (entryIntroText && isFullyMatched(entryIntroText)) {
    text = entryIntroText.value;

    // Check for match in parishes and churches
  } else if (churchesAndParishes && isFullyMatched(churchesAndParishes)) {
    text = churchesAndParishes.value;

    // Else check for match in location description
  } else if (locationDescription && isFullyMatched(locationDescription)) {
    text = locationDescription.value;
  } else {
    text = entryIntroText ? entryIntroText.value : null;
  }

  return text;
}
</script>
