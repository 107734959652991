<template>
  <div :class="rootClass">
    <div v-show="churches.list.length > 0" :class="[cn + '__searchBar', 'relative flex space-x-2']">
      <SimpleTypeahead
        v-if="churches.list.length > 0"
        ref="searchbar"
        :class="[
          small || medium ? 'h-12 px-10 pl-3' : 'h-11 px-4',
          'w-full rounded-sm border border-gray-300 focus:border-[#5bc3be]',
        ]"
        placeholder="Kirche suchen ..."
        :items="churches.list"
        :min-input-length="0"
        :item-projection="
          (item) => {
            return `${item.title}<p>${item.town}</p>`;
          }
        "
        @select-item="handleItemSelect"
      >
        <template v-if="!small" #list-header>
          <div :class="[cn + '__disclaimerWrapper']">
            <div :class="[cn + '__disclaimerContent']">
              <div :class="[cn + '__infoIconWrapper']">
                <InfoIcon :style="'width: 100%; height: 100%;'" />
              </div>
              <div>
                Derzeit sind noch nicht für alle Kirchen die Gottesdienstzeiten online verfügbar.
                Die Daten werden laufend erweitert.
              </div>
            </div>
          </div>
        </template>

        <template #list-item-text="slot">
          <div :class="[cn + '__churchLinkWrapper']">
            <div
              :class="cn + '__churchText'"
              v-html="highlight(slot.itemProjection(slot.item))"
            ></div>

            <div v-if="!small && slot.item.hasServicesEnabled" :class="cn + '__churchLink'">
              Zu den Gottesdiensten
              <div :class="[cn + '__linkExternalIconWrapper']">
                <LinkExternalIcon :style="'width: 100%; height: 100%;'" />
              </div>
            </div>

            <div
              v-if="!small && !slot.item.hasServicesEnabled"
              :class="cn + '__churchLink is-disabled'"
            >
              Bald verfügbar
            </div>
          </div>
        </template>
      </SimpleTypeahead>

      <SearchIcon v-if="small || medium" />

      <input type="hidden" :value="churches.selectedId" name="church" />
      <button
        v-if="!small && !medium"
        class="a-button a-button--small a-button--styleTurquoise"
        :class="cn + '__searchButton'"
      >
        Suchen
      </button>
    </div>
  </div>
</template>

<script setup>
import axios from 'axios';
import SimpleTypeahead from 'vue3-simple-typeahead';
import { reactive, ref, defineProps, watch } from 'vue';

import InfoIcon from './__infoIcon.vue';
import LinkExternalIcon from './__linkExternalIcon.vue';
import SearchIcon from './__searchIcon.vue';

const churches = reactive({
  list: [],
  selectedId: undefined,
  selectedItem: undefined,
});

const cn = 'o-searchChurchesSearch';
const searchbar = ref(null);

const props = defineProps({
  small: Boolean,
  showinfo: Boolean,
  medium: Boolean,
  preselected: String,
  churchids: Array,
  applyOnSelect: Boolean,
});

const urls = ['/intentio/active-churches', '/intentio/inactive-churches'];
const requests = urls.map((url) => axios.get(url));

watch(searchbar, () => {
  if (props.preselected) {
    searchbar.value.input = churches.selectedItem?.title;
  }
});

axios
  .all(requests)
  .then((responses) => {
    responses.forEach(({ data }) => {
      if (props.churchids) {
        churches.list.push(
          ...data.filter((item) => props.churchids.includes(parseInt(item.churchId))),
        );
      } else {
        churches.list.push(...data);
      }
    });
    if (props.preselected) {
      const preselectedItem = churches.list.find(
        (item) => item.churchId.toString() === props.preselected.toString(),
      );
      churches.selectedItem = preselectedItem;
    }
  })
  .catch((e) => console.error(e));

const handleItemSelect = (item) => {
  const form = searchbar.value.$el.closest('form');
  if (props.applyOnSelect && form) {
    const url = new URL(form.action);
    url.searchParams.set('church', item.churchId);
    window.location = url;
  } else {
    searchbar.value.input = item.title;

    if (item.hasServicesEnabled) {
      churches.selectedId = item.churchId;

      if (!props.small && !props.medium) {
        window.location = item.churchLink;
      }
    }
  }
};

const highlight = (words) => {
  if (!searchbar.value || !searchbar.value.input) {
    return words;
  }
  const regexp = new RegExp(`(${searchbar.value.input})`, 'ig');
  return words.replace(regexp, '<em class="highlight">$1</em>');
};
</script>
