<template>
  <div :class="rootClass">
    <div :class="`${cn}__box`">
      {{ text }}
    </div>
  </div>
</template>

<script setup lang="ts">
import useRootClass from '../../../src/composables/useRootClass';

interface IMarketteProps {
  modifiers?: [];
  text?: string;
}

const props = withDefaults(defineProps<IMarketteProps>(), {
  modifiers: () => [],
  text: '',
});

const cn = 'a-markette';

const { rootClass } = useRootClass({
  cn,
  modifiers: props.modifiers,
});
</script>
