<template>
  <div v-if="bell" ref="accWrapper" class="o-bellDetail o-bellDetail--withNumber w-full py-2">
    <div
      ref="target"
      class="flex flex-col gap-4 md:flex-row md:justify-between md:gap-0"
      role="tab"
    >
      <div
        class="o-bellDetail__properties mx-2 flex flex-grow flex-wrap items-stretch justify-start gap-4 md:flex-nowrap"
      >
        <div class="-order-10 flex md:flex-row">
          <div class="o-bellDetail__player mr-4 ml-2 flex items-center">
            <AudioPlayer id="33105" :src="item[1]" :active="!!item[1]" />
          </div>
          <div class="o-bellDetail__image o-bellDetail__listItem o-bellDetail__listItem--text">
            <img v-if="item[0]" :src="item[0]" alt="bell image" />
            <img v-else :src="bellimg" alt="bell image" />
          </div>
        </div>

        <div
          v-for="property in overviewProperties"
          :key="property"
          class="o-bellDetail__listItem o-bellDetail__listItem--text"
          :class="`o-bellDetail__listItem--${property}`"
        >
          <span class="o-bellDetail__listItemValue"
            >{{ getPropertyValue(property) }}{{ property === 'bellGewicht' ? ' kg' : '' }}</span
          >
          <span class="o-bellDetail__listItemLabel">
            {{
              property === 'bellName' && isFormerBell(combined.bellNumber)
                ? translations.bellNameFormer
                : translations[property]
            }}
          </span>
        </div>
      </div>

      <div
        v-if="checkForContent()"
        ref="dropDownArrow"
        class="o-bellDetail__detailLink o-bellDetail__listItem o-bellDetail__listItem--text js-bellDetailTrigger"
        @click="toggleContent($event)"
      >
        <div class="o-bellDetail__detailLink--link">
          <div class="o-bellDetail__detailLink--linkText">Details</div>

          <span class="a-svgIcon a-svgIcon--drop-down-arrow">
            <svg width="11" height="7" viewBox="0 0 11 7" xmlns="http://www.w3.org/2000/svg">
              <title>drop-down-arrow</title>
              <path d="M11 1.4L9.625 0 5.5 4.2 1.375 0 0 1.4 5.5 7z" fill="#46A8A3" />
            </svg>
          </span>
        </div>
      </div>
    </div>

    <div ref="accContent" class="o-bellDetail__detailContent" role="tabpanel">
      <div class="o-bellDetail__detailContentContainer ml-0 mt-6 px-2 md:mt-0 md:pl-0 md:pr-4">
        <div v-if="musicalProperties.length" class="o-bellDetail__dividerAndProps">
          <div class="a-divider a-divider--bellProperties">
            <div class="a-divider__wrapper">
              <div class="a-divider__line" />
            </div>
          </div>

          <div class="py-6 md:flex">
            <div class="o-bellDetail__propertiesGroupHeadline">Musikalische Daten:</div>

            <div class="o-bellDetail__properties grid grid-cols-3 md:grid-cols-4">
              <div
                v-for="property in musicalProperties"
                :key="property"
                class="o-bellDetail__listItem o-bellDetail__listItem--text"
              >
                <span class="o-bellDetail__listItemValue"
                  >{{ getPropertyValue(property)
                  }}{{ property === 'bellGewicht' ? ' kg' : '' }}</span
                >
                <span class="o-bellDetail__listItemLabel">{{ translations[property] }}</span>
              </div>
            </div>
          </div>
        </div>

        <div v-if="checkProperties(technical)" class="o-bellDetail__dividerAndProps">
          <div class="a-divider a-divider--bellProperties">
            <div class="a-divider__wrapper">
              <div class="a-divider__line" />
            </div>
          </div>

          <div class="py-6 md:flex">
            <div class="o-bellDetail__propertiesGroupHeadline">Technische Daten:</div>
            <div class="o-bellDetail__properties grid grid-cols-3 md:grid-cols-4">
              <div
                v-for="property in technicalProperties"
                :key="property"
                class="o-bellDetail__listItem o-bellDetail__listItem--text"
              >
                <span class="o-bellDetail__listItemValue"
                  >{{ getPropertyValue(property)
                  }}{{ property === 'bellGewicht' ? ' kg' : '' }}</span
                >
                <span class="o-bellDetail__listItemLabel">{{ translations[property] }}</span>
              </div>
            </div>
          </div>
        </div>

        <div v-if="checkProperties(inscription)" class="o-bellDetail__dividerAndProps">
          <div class="a-divider a-divider--bellProperties">
            <div class="a-divider__wrapper">
              <div class="a-divider__line" />
            </div>
          </div>

          <div class="py-6 md:flex">
            <div class="o-bellDetail__propertiesGroupHeadline">Beschriftung und Verzierung:</div>

            <div class="o-bellDetail__properties gap-6 pr-4">
              <div
                v-for="(property, key) in inscription"
                v-show="!!property"
                :key="key"
                class="o-bellDetail__listItem o-bellDetail__listItem--text"
              >
                <span class="o-bellDetail__listItemValue">{{ property }}</span>
                <span class="o-bellDetail__listItemLabel">{{ translations[key] }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AudioPlayer from '../../_atoms/audioHtmlPlayer/_component.vue';

export default {
  name: 'BellItem',

  components: {
    AudioPlayer,
  },

  props: {
    modifiers: {
      type: Array,
      default: () => [],
    },
    bell: {
      type: Array,
      default: null,
    },
    bellimg: {
      type: String,
      default: null,
    },
    overviewProperties: {
      type: Array,
      default: () => ['bellNumber', 'bellName', 'bellNominal', 'bellGiesser', 'bellGussjahr'],
    },
    technicalProperties: {
      type: Array,
      default: () => [
        'bellMaterial',
        'bellGewicht',
        'bellDurchmesser',
        'bellSchlagring',
        'bellRippenschwere',
        'bellJoch',
        'bellStuhl',
      ],
    },
    musicalProperties: {
      type: Array,
      default: () => [
        'bellType',
        'bellUnterton',
        'bellPrim',
        'bellTerz',
        'bellQuint',
        'bellOktav',
        'bellDezime',
        'bellDuodezime',
      ],
    },
  },

  data() {
    return {
      cn: 'm-bellSearch',
      item: null,
      overview: null,
      musical: null,
      technical: null,
      inscription: null,
      combined: null,
      translations: {
        bellGussjahr: 'Gussjahr',
        bellMaterial: 'Material',
        bellGewicht: 'Gewicht',
        bellDurchmesser: 'Ø',
        bellStand: 'Stand',
        bellAufnahmeort: 'Aufnahmeort',
        bellNotiz: 'Notiz',
        bellType: 'Typ',
        bellNominal: 'Nominal',
        bellUnterton: 'Unterton',
        bellPrim: 'Prim',
        bellTerz: 'Terz',
        bellQuint: 'Quint',
        bellOktav: 'Oktav',
        bellDezime: 'Dezime',
        bellDuodezime: 'Duodezime',
        bellSchlagring: 'Schlagring',
        bellRippenschwere: 'Rippenschwere',
        bellAbklingdauer: 'Abklingdauer',
        bellLautewinkel: 'Läutewinkel',
        bellAMin: 'A/min"',
        bellADin: 'A DIN',
        bellJoch: 'Joch',
        bellStuhl: 'Stuhl',
        bellKloppel: 'Klöppel',
        bellGiesser: 'Gießer',
        bellDrehung: 'Drehung',
        bellVorne: 'Vorne',
        bellHinten: 'Hinten',
        bellKrone: 'Krone',
        bellHaube: 'Haube',
        bellSchulter: 'Schulter',
        bellWolm: 'Wolm',
        bellUntererRand: 'Unterer Rand',
        bellInnen: 'Innen',
        bellSonstiges: 'Sonstiges',
        bellName: 'Name',
        bellNameFormer: 'Ehemalige Glocke',
        bellNumber: 'Nr',
      },
    };
  },

  created() {
    [this.item, this.overview, this.technical, this.musical, this.inscription] = this.bell;
    this.combined = { ...this.overview, ...this.technical, ...this.musical, ...this.inscription };
  },

  methods: {
    toggleContent(event) {
      event.preventDefault();
      if (this.$refs.target && this.$refs.accContent) {
        if (!this.$refs.target.classList.contains('is-open')) {
          this.$refs.target.classList.add('is-open');
          this.$refs.accWrapper.classList.add('is-open');
          this.$refs.accContent.style.display = 'block';
        } else {
          this.$refs.target.classList.remove('is-open');
          this.$refs.accWrapper.classList.remove('is-open');
          this.$refs.accContent.style.display = 'none';
        }
      }
    },
    checkProperties(obj) {
            for (const key in obj) { // eslint-disable-line
        if (obj[key] !== null && obj[key] !== '') return true;
      }
      return false;
    },
    checkForContent() {
      return (
        this.checkProperties(this.musical) ||
        this.checkProperties(this.technical) ||
        this.checkProperties(this.inscription)
      );
    },
    isFormerBell(str) {
      if (str) {
        return /^\(\d+\)$/.test(str.trim());
      }
      return false;
    },
    getPropertyValue(property) {
      if (property == 'bellRippenschwere') {
        const normalizedValue = parseFloat(this.combined[property]?.replace(',', '.'));
        return (Math.round(normalizedValue * 10) / 10).toFixed(1).replace('.', ',');
      }
      return this.combined[property] ? this.combined[property] : '—';
    },
  },
};
</script>
