/*
 * Description of what this file is for
 *
 * @package  baukasten
 * @author Martin Hellwagner
 * comments
 */

const comments = {
  cfg: {
    name: 'comments',
    selectors: {
      comments: '.o-comments',
      readMoreLabels: '.readMore',
      readLessLabels: '.readLess',
      truncatedText: '.text--truncated',
      nonTruncatedText: '.text--nonTruncated',
      upvoteAreas: '.meta__voting',
      upvoteCandles: '.meta__candles',
      upvoteCandlesInactive: '.meta__candles--inactive',
      upvoteTriggers: '.upvoteTrigger',
      upvoteNumbers: '.upvoteNumber',
      upvoteLabels: '.upvoteLabel',
    },
    el: {
      $comments: undefined,
    },
    els: {
      $readMoreLabels: undefined,
      $readLessLabels: undefined,
      $upvoteAreas: undefined,
      $upvoteCandles: undefined,
      $upvoteCandlesInactive: undefined,
      $upvoteTriggers: undefined,
    },
  },

  /* eslint-disable */
    serializeObject(json, csrfTokenName, csrfToken) {
        const qs = Object.keys(json).map(function (key) {
            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
        });

        // Add CSRF to each request
        qs.push(encodeURIComponent(csrfTokenName) + '=' + encodeURIComponent(csrfToken));

        return qs.join('&');
    },
    /* eslint-enable */

  ajax(url, settings) {
    settings = settings || {};

    const xhr = new XMLHttpRequest();
    xhr.open(settings.method || 'GET', url, true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        let response = JSON.parse(xhr.responseText);

        if (xhr.status === 200 && settings.success) {
          if (response.errors) {
            settings.error(response);
          } else {
            settings.success(response);
          }
        } else if (xhr.status !== 200 && settings.error) {
          if (response.error) {
            response = [[response.errors]];
          }
          settings.error(response);
        }
      }
    };
    xhr.send(settings.data || '');
  },

  lightCandle(trigger) {
    const parent = trigger.parentNode.parentNode.parentNode;
    let count = parseInt(parent.querySelector(this.cfg.selectors.upvoteNumbers).innerHTML, 10);

    if (!count) {
      count = 0;
    }
    count += 1;

    // Send number of candles (maxmimum of 10+1) to stylesheet
    parent.querySelector(this.cfg.selectors.upvoteCandles).style.width = `${
      Math.min(count, 11) * 12
    }px`;
    parent.querySelector(this.cfg.selectors.upvoteCandles).style.minWidth = `${
      Math.min(count, 11) * 12
    }px`;
    parent.querySelector(this.cfg.selectors.upvoteCandlesInactive).classList.add('is-hidden');
    parent.querySelector(this.cfg.selectors.upvoteTriggers).classList.add('is-hidden');
    parent.querySelector(this.cfg.selectors.upvoteLabels).style.left = '20px';

    if (count === 0) {
      parent.querySelector(this.cfg.selectors.upvoteLabels).innerHTML = 'Entzünde die erste Kerze';
    } else if (count === 1) {
      parent.querySelector(this.cfg.selectors.upvoteLabels).innerHTML = '1 Kerze entzündet';
    } else if (count > 1) {
      parent.querySelector(this.cfg.selectors.upvoteLabels).innerHTML = `${count} Kerzen entzündet`;
    }
  },

  init() {
    this.cfg.el.$comments = document.querySelector(this.cfg.selectors.comments);

    if (this.cfg.el.$comments) {
      this.cfg.els.$readMoreLabels = this.cfg.el.$comments.querySelectorAll(
        this.cfg.selectors.readMoreLabels,
      );
      this.cfg.els.$readLessLabels = this.cfg.el.$comments.querySelectorAll(
        this.cfg.selectors.readLessLabels,
      );
      this.cfg.els.$upvoteAreas = this.cfg.el.$comments.querySelectorAll(
        this.cfg.selectors.upvoteAreas,
      );

      // Set "read more" trigger
      this.cfg.els.$readMoreLabels.forEach(($readMoreLabel) => {
        $readMoreLabel.addEventListener('click', (e) => {
          const parent = e.target.parentNode.parentNode;
          parent.querySelector(this.cfg.selectors.truncatedText).classList.add('is-hidden');
          parent.querySelector(this.cfg.selectors.nonTruncatedText).classList.remove('is-hidden');
        });
      });

      // Set "read less" trigger
      this.cfg.els.$readLessLabels.forEach(($readLessLabel) => {
        $readLessLabel.addEventListener('click', (e) => {
          const parent = e.target.parentNode.parentNode;
          parent.querySelector(this.cfg.selectors.truncatedText).classList.remove('is-hidden');
          parent.querySelector(this.cfg.selectors.nonTruncatedText).classList.add('is-hidden');
        });
      });

      if (this.cfg.els.$upvoteAreas) {
        this.cfg.els.$upvoteCandles = this.cfg.el.$comments.querySelectorAll(
          this.cfg.selectors.upvoteCandles,
        );
        this.cfg.els.$upvoteCandlesInactive = this.cfg.el.$comments.querySelectorAll(
          this.cfg.selectors.upvoteCandlesInactive,
        );
        this.cfg.els.$upvoteTriggers = this.cfg.el.$comments.querySelectorAll(
          this.cfg.selectors.upvoteTriggers,
        );
        this.cfg.els.$upvoteLabels = this.cfg.el.$comments.querySelectorAll(
          this.cfg.selectors.upvoteLabels,
        );
        this.cfg.els.$upvoteNumbers = this.cfg.el.$comments.querySelectorAll(
          this.cfg.selectors.upvoteNumbers,
        );

        // Set initial upvotes
        this.cfg.els.$upvoteAreas.forEach(($upvoteArea) => {
          const initialCount = parseInt(
            $upvoteArea.querySelector(this.cfg.selectors.upvoteNumbers).innerHTML,
            10,
          );
          $upvoteArea.querySelector(this.cfg.selectors.upvoteCandles).style.width = `${
            Math.min(initialCount, 10) * 12
          }px`;
          $upvoteArea.querySelector(this.cfg.selectors.upvoteCandles).style.minWidth = `${
            Math.min(initialCount, 10) * 12
          }px`;
        });

        // Handle upvote
        this.cfg.els.$upvoteTriggers.forEach(($upvoteTrigger) => {
          $upvoteTrigger.addEventListener('click', (e) => {
            e.preventDefault();

            const url = e.target.getAttribute('data-url').split('?')[0];
            const commentId = e.target.getAttribute('data-comment-id');
            const siteId = e.target.getAttribute('data-site-id');
            const csrfTokenName = e.target.getAttribute('data-csrf-token-name');
            const csrfToken = e.target.getAttribute('data-csrf-token');

            this.ajax(url, {
              method: 'POST',
              data: this.serializeObject(
                {
                  commentId: commentId,
                  siteId: siteId,
                  upvote: true,
                },
                csrfTokenName,
                csrfToken,
              ),
              success: () => {
                this.lightCandle(e.target);
              },
              error: () => {
                //
              },
            });
          });
        });
      }
    }
  },
};

export default comments;
