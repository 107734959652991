const getAncestorWith = (selector, el) => {
  let currentElement = el;

  while (
    currentElement &&
    !(currentElement.matches || currentElement.msMatchesSelector).call(currentElement, selector)
  ) {
    currentElement = currentElement.parentElement;
  }

  return currentElement;
};

export default getAncestorWith;
