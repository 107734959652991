<template>
  <div :class="rootClass">
    <div class="o-bellSearch__search">
      <div class="o-bellSearch__search--inner">
        <h2>Glockensuche des Bistums Passau</h2>
        <div class="o-bellSearch__searchFormWrapper">
          <form class="o-bellSearch__searchForm" @submit.prevent="submit">
            <div class="select__wrapper">
              <select
                v-if="churchList && churchList.length > 0"
                v-model="selectedChurch"
                @change="removeFocusFromElement"
              >
                <option :value="null" disabled selected>Pfarrverband auswählen</option>
                <option v-for="item in churchList" :key="item.id" :value="item">
                  {{ item.title }}
                </option>
              </select>
            </div>
            <button
              :disabled="loading || selectedChurch === null"
              type="submit"
              class="a-button a-button--styleTurquoise m-bellSearch__submit"
            >
              Glockendatenbank durchsuchen
            </button>
          </form>
        </div>
      </div>
    </div>

    <div v-if="churches && churches.length > 0" class="o-bellSearch__results">
      <div class="o-bellSearch__resultChurchHeadline">
        <span class="o-bellSearch__resultText--bold">{{ bellsCount }} Glocken</span> aus
        <span class="o-bellSearch__resultText--bold">{{ numberOfChurchesWithBells }} Kirchen </span>
        des Pfarrverbandes {{ selectedChurchSubmitted.title }} gefunden.
      </div>
      <template v-for="church in churches" :key="church[0].id">
        <div v-if="church[2].length > 0">
          <div class="o-bellSearch__resultChurchCluster">
            <h3>{{ church[3] ? church[3] : church[0].title }}</h3>

            <div class="a-link a-link--noBorder" itemprop="url" role="button">
              <div class="a-link__linkWrapper">
                <a :href="church[1]" class="a-link__link">
                  <span class="a-link__text">Zur Kirche</span>
                  <span class="a-svgIcon a-svgIcon--vueSearchLink" />
                </a>
              </div>
            </div>
          </div>

          <div class="a-divider a-divider--afterSearchHeadline">
            <div class="a-divider__wrapper">
              <div class="a-divider__line" />
            </div>
          </div>
        </div>
        <div v-for="(bell, index) in church[2]" :key="index">
          <BellItem :bell="bell" :bellimg="fallBackImg" />
          <div class="a-divider a-divider--bell">
            <div class="a-divider__wrapper">
              <div class="a-divider__line" />
            </div>
          </div>
        </div>
      </template>
    </div>
    <div v-else-if="filteredBells" class="o-bellSearch__results">
      Für die Kirche {{ selectedChurchSubmitted.title }} wurden keine Glocken gefunden.
    </div>
  </div>
</template>

<script setup lang="ts">
import { IChurch } from './IChurch';
import BellItem from './_bellItem.vue';
import { computed, defineProps, ref, Ref, withDefaults } from 'vue';
import axios from 'axios';
import useRootClass from '../../../src/composables/useRootClass';

interface IProps {
  modifiers?: string[];
}

const props = withDefaults(defineProps<IProps>(), {
  modifiers: () => [],
});

const cn = 'm-bellSearch';
const churchList = ref<IChurch[]>();
const fallBackImg = ref('');
const selectedChurch = ref<IChurch | null>(null);
const selectedChurchSubmitted = ref<IChurch>();
const loading = ref(false);
const defaultBells: Ref<HTMLElement | null> = ref(null);
const filteredBells = ref<[] | null>(null);
const churches = ref([]);

const { rootClass } = useRootClass({ cn, modifiers: props.modifiers });

axios
  .get('/bellsearch/parishac')
  .then(({ data }) => {
    [churchList.value, fallBackImg.value] = data;
  })
  .catch((e) => console.error(e));

defaultBells.value = document.querySelector('.o-bellSearch__results--beforeSearch');

async function submit() {
  if (!selectedChurch.value) {
    return;
  }
  try {
    const filterUrl = `/bellsearch/filter/${selectedChurch.value.id}`;
    loading.value = true;
    const { data } = await axios.get(filterUrl, {
      params: { paId: selectedChurch.value.id },
    });
    churches.value = data;
    filteredBells.value = data;
    selectedChurchSubmitted.value = selectedChurch.value;
    if (defaultBells.value) {
      defaultBells.value.style.display = 'none';
    }
    loading.value = false;
  } catch (e) {
    console.error(e);
    loading.value = false;
  }
}

const bellsCount = computed(() => {
  let num = 0;
  for (let i = 0; i < churches.value.length; i++) {
    if (churches.value[i][2].length > 0) {
      num += churches.value[i][2].length;
    }
  }
  return num;
});

const numberOfChurchesWithBells = computed(() => {
  let num = 0;
  for (let i = 0; i < churches.value.length; i++) {
    if (churches.value[i][2].length > 0) {
      num += 1;
    }
  }
  return num;
});

function removeFocusFromElement(ev) {
  ev.target.blur();
}
</script>

<style>
.select__wrapper {
  position: relative;
}

.select__wrapper select {
  appearance: none;
  width: 100%;
  background: #fff;
  border-radius: 3px;
  border: 1px solid #d8d8d8;
  outline: none;
  cursor: pointer;
  padding: 14px 6px;
}

.select__wrapper select option {
  color: #666;
}

.select__wrapper select:focus::-ms-value {
  background-color: transparent;
}

.select__wrapper::before {
  background: url('/dist/images/svg/single/drop-down-arrow.svg') no-repeat;
  width: 15px;
  height: 15px;
  content: '';
  font-size: 20px;
  position: absolute;
  right: 6px;
  top: 23px;
  pointer-events: none;
}
@media (min-width: 400px) {
  .select__wrapper select {
    padding: 14px 10px;
  }

  .select__wrapper::before {
    right: 10px;
  }
}
@media (min-width: 800px) {
  .select__wrapper {
    display: inline-block;
  }

  .select__wrapper select {
    padding: 14px;
  }

  .select__wrapper::before {
    right: 40px;
  }
}

.o-bellSearch__resultText--bold {
  font-weight: 500;
}

.a-svgIcon--vueSearchLink {
  background: url('/dist/images/svg/single/iconTurquoiseArrowRight.svg') no-repeat;
}
</style>
