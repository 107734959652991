<template>
  <div :class="rootClass">
    <h4 class="m-pollItem__heading">{{ pollItem.pollQuestion }}</h4>
    <transition name="fade" mode="out-in">
      <div v-if="showPoll" key="1" class="m-pollItem__box">
        <form @submit.prevent="submit">
          <div v-for="answer in pollItem.pollAnswers" :key="answer.id" class="m-pollItem__answer">
            <label class="m-pollItem__checkmarkContainer">
              {{ answer.answer }}
              <input
                :id="'answer-' + answer.id"
                v-model="selectedAnswer"
                :value="answer.id"
                type="radio"
              />
              <span class="m-pollItem__radioCheckmark" />
            </label>
          </div>
          <!-- todo translate-->
          <button
            :disabled="loading"
            type="submit"
            class="a-button a-button--styleTurquoise m-pollItem__submit"
          >
            Abstimmen
          </button>
          <span v-if="error" class="m-pollItem__error">
            {{ errorMessage }}
          </span>
        </form>
      </div>
      <div v-else key="2" class="m-pollItem__box">
        <div v-for="answer in pollItem.pollAnswers" :key="answer.id" class="m-pollItem__boxItem">
          <div class="m-pollItem__boxItem--inner">
            <span class="m-pollItem__boxItemAnswer">
              {{ answer.answer }}
            </span>
            <span class="m-pollItem__boxItemPercent">
              {{ ((answer.votes / sumVotes) * 100).toFixed(0) + ' %' }}
            </span>
          </div>
          <div class="m-pollItem__progressBarWrapper">
            <div
              :style="'width: ' + (answer.votes / sumVotes) * 100 + '%'"
              class="m-pollItem__progressBar"
            />
          </div>
        </div>
        <!-- todo translate-->
        <div class="m-pollItem__outro">Danke fürs Mitmachen!</div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import useRootClass from '../../../src/composables/useRootClass';
import { computed, inject, ref } from 'vue';
import axios from 'axios';

interface IPollItem {
  pollId: number;
  pollAnswers: [];
  pollQuestions: [];
  isPollActive: boolean;
}
const pollItem: IPollItem | undefined = inject('pollItem');
interface IProps {
  modifiers?: string[];
}

const props = withDefaults(defineProps<IProps>(), {
  modifiers: () => [],
});

const cn = 'm-pollItem';
const selectedAnswer = ref(null);
const formSubmitted = ref(false);
const loading = ref(false);
const error = ref(false);
const errorMessage = ref('');

const { rootClass } = useRootClass({ cn, modifiers: props.modifiers });

const sumVotes = computed(() => {
  let sum = 0;
  if (!pollItem) return sum;
  for (let i = 0; i < pollItem.pollAnswers.length; i++) {
    sum += parseInt(pollItem.pollAnswers[i].votes, 10);
  }
  return sum;
});

function updateVotes(submittedAnswerId: number) {
  if (!pollItem) return;
  // find user's answer and update votes value
  const result = pollItem.pollAnswers.find((answer) => answer.id === submittedAnswerId);
  if (result) {
    result.votes = parseInt(result.votes, 10) + 1;
  }
}

function setFormCookie(pollId: number) {
  const days = 10;
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `; expires= ${date.toGMTString()}`;
  document.cookie = `poll-${pollId} = submitted ${expires} ; path=/`;
}

async function submit() {
  if (!selectedAnswer.value) return;

  loading.value = true;
  const postData = {
    answerid: selectedAnswer.value,
  };

  const axiosConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  };

  try {
    await axios.post('/poll/incrementAnswer', postData, axiosConfig);
    updateVotes(selectedAnswer.value);
    loading.value = false;
    error.value = false;
    formSubmitted.value = true;
    setFormCookie(pollItem.pollId);
  } catch (e) {
    console.error(e);
    errorMessage.value = 'Fehler beim Absenden des Formulars';
    error.value = true;
    loading.value = false;
  }
}

function getCookie(cname: string) {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

const hasCookie = computed(() => {
  const pollCookieName = `poll-${pollItem.pollId}`;
  const pollCookie = getCookie(pollCookieName);
  if (pollCookie !== '') {
    return true;
  }
  return false;
});

const showPoll = computed(() => {
  if (formSubmitted.value || hasCookie.value) return false;
  return true;
});
</script>
