<template>
  <div :class="rootClass">
    <a :href="link">
      <img
        v-if="imageSrc"
        :src="imageSrc"
        :alt="headline"
        sizes="(max-width: 400px) 150vw, 266px"
        class="m-searchResult__image lazyload"
      />
      <div>
        <h4>
          <span><span v-html="headline" /></span>
        </h4>
        <div class="m-searchResult__category">
          {{ category }}
          <span v-show="dateFormatted" class="m-searchResult__date"
            ><span v-if="category">-</span> {{ dateFormatted }}</span
          >
        </div>
        <p v-html="teaserText" />
      </div>
    </a>
  </div>
</template>

<script setup lang="ts">
import useRootClass from '../../../src/composables/useRootClass';
import { computed } from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/de';

interface ISearchResultProps {
  modifiers?: [];
  headline?: string;
  category?: string;
  date?: string;
  teaserText?: string;
  imageSrc?: string;
  link?: string;
}

const props = withDefaults(defineProps<ISearchResultProps>(), {
  modifiers: () => [],
  headline: '',
  category: '',
  date: '',
  teaserText: '',
  imageSrc: '',
  link: '',
});

const cn = 'm-searchResult';
const { rootClass } = useRootClass({
  cn,
  modifiers: props.modifiers,
});

dayjs.locale('de');

const dateFormatted = computed(() => {
  if (props.date) {
    return dayjs(props.date).format('DD. MMMM YYYY');
  }

  return null;
});
</script>
