<!-- eslint-disable -->
<template>
  <svg width="21px"
       height="22px"
       viewBox="0 0 21 22"
       version="1.1"
       xmlns="http://www.w3.org/2000/svg"
       xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Symbols"
       stroke="none"
       stroke-width="1"
       fill="none"
       fill-rule="evenodd">
      <g id="mobile-/-nav-open" transform="translate(-257.000000, -47.000000)" fill="#5D5D5D">
        <g id="hamburger-x">
          <path d="M267.5,56.5857864 L276.338835,47.7469517 L277.753048,49.1611652 L268.914214,58 L277.753048,66.8388348 L276.338835,68.2530483 L267.5,59.4142136 L258.661165,68.2530483 L257.246952,66.8388348 L266.085786,58 L257.246952,49.1611652 L258.661165,47.7469517 L267.5,56.5857864 Z" />
        </g>
      </g>
    </g>
  </svg>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'SearchCloseIcon',
};
</script>
