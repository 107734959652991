<template>
  <div :class="rootClass">
    <a :class="`${cn}__link`" :href="link">
      <div :class="`${cn}__wrapper`">
        <!-- {# ----- DATE BLOCK ----- #} -->
        <div :class="`${cn}__date`">{{ startDateFormatted }}</div>

        <!-- {# ----- DESCRIPTION BLOCK ----- #} -->
        <div :class="`${cn}__content`">
          <div :class="`${cn}__markette`">
            <Markette v-if="label" :modifiers="['small']" :text="label" />
          </div>

          <h4 :class="`${cn}__headline`">
            <span>{{ headline }}</span>
          </h4>

          <p :class="`${cn}__dayTime`">
            {{ dateString }}{{ locationTown && locationTitle ? ',' : '' }}
            <span v-show="locationTown && locationTitle" :class="`${cn}__descriptionText`">
              {{ locationTown }}, {{ locationTitle }}
            </span>
          </p>
        </div>
      </div>
    </a>
  </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import Markette from '../../_atoms/markette/_component.vue';
import useRootClass from '../../../src/composables/useRootClass';
import { computed } from 'vue';

interface ITeaserDateSidebarItemProps {
  headline: string;
  startDate: string;
  link: string;
  modifiers?: [];
  label?: string;
  locationTown?: string;
  locationTitle?: string;
  endDate?: string | null;
  startTime?: string | null;
  endTime?: string | null;
}

const props = withDefaults(defineProps<ITeaserDateSidebarItemProps>(), {
  modifiers: () => [],
  label: '',
  locationTown: '',
  locationTitle: '',
  endDate: null,
  startTime: null,
  endTime: null,
});

const cn = 'm-teaserDateSitebarItem';
const { rootClass } = useRootClass({
  cn,
  modifiers: props.modifiers,
});

dayjs.locale('de');

const startDateFormatted = computed(() => {
  return dayjs(props.startDate).format('DD.MM.');
});

function formatStartEndDate(date: string) {
  return dayjs(date).format('dddd, DD.MM.');
}

function formatStartEndTime(time: string) {
  return dayjs(time).format('HH:mm');
}

const dateString = computed(() => {
  const startDate = formatStartEndDate(props.startDate);
  const endDate = props.endDate ? formatStartEndDate(props.endDate) : null;
  const startTime = props.startTime ? formatStartEndTime(props.startTime) : null;
  const endTime = props.endTime ? formatStartEndTime(props.endTime) : null;

  if (props.endDate && props.startTime && props.endTime) {
    return `${startDate}, ${startTime} Uhr bis ${endDate}, ${endTime} Uhr`;
  } else if (props.endDate && props.startTime) {
    return `${startDate}, ${startTime} Uhr bis ${endDate}`;
  } else if (props.endDate && props.endTime) {
    return `${startTime} bis ${endDate}, ${endTime} Uhr`;
  } else if (props.endDate) {
    return `${startTime} bis ${endDate}`;
  } else if (props.startTime) {
    return `${startDate}, ${startTime} Uhr`;
  }

  return startDate;
});
</script>
