<!-- eslint-disable -->
<template>
  <svg width="24px" height="16px" viewBox="0 0 24 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 57.1 (83088) - https://sketch.com -->
    <title>external-link</title>
    <desc>Created with Sketch.</desc>
    <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="external-link">
        <g transform="translate(1.000000, 1.000000)">
          <path d="M14,10 L14,10.5 C14,12.4329966 12.4329966,14 10.5,14 L3.5,14 C1.56700338,14 0,12.4329966 0,10.5 L0,3.5 C0,1.56700338 1.56700338,0 3.5,0 L10.5,0 C12.4329966,0 14,1.56700338 14,3.5 L14,4" id="Path" stroke="#5BC3BE" stroke-width="2" opacity="0.5"></path>
          <path d="M17.1111111,1.58333333 L22.8888889,7 L17.1111111,12.4166667 L15.6666667,11.0625 L20,7 L15.6666667,2.9375 L17.1111111,1.58333333 Z M17,6 L17,8 L4,8 L4,6 L17,6 Z" id="arrow" fill="#5BC3BE" fill-rule="nonzero"></path>
        </g>
      </g>
    </g>
  </svg>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'LinkExternalIcon',
};
</script>
