/**
 * imageGallery (Photoswipe)
 */

import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';
import {
  arrowRight as arrowNextSVG,
  arrowLeft as arrowPrevSVG,
  close as closeSVG,
  zoom as zoomSVG,
} from './_ui-elements';

const imageGallery = {
  cfg: {
    name: 'imageGallery',
    selectors: {
      galleries: 'js-imageGallery',
    },
    classes: {},
  },
  photoswipeOptions: {
    // see https://photoswipe.com/options/ for all options
    bgOpacity: 1,
    counter: false,
    arrowNextSVG,
    arrowPrevSVG,
    closeSVG,
    zoomSVG,
  },
  galleriesOnPage: [],

  initGalleryInstance(galleryElement) {
    galleryElement.dataset.galleryId = this.galleriesOnPage.length;

    const galleryInstance = new PhotoSwipeLightbox({
      gallery: galleryElement,
      children: 'figure',
      pswpModule: PhotoSwipe,
      ...this.photoswipeOptions,
    });

    galleryInstance.on('uiRegister', function() {
        galleryInstance.pswp.ui.registerElement({
          name: 'caption',
          order: 9,
          isButton: false,
          appendTo: 'wrapper',
          onInit: (el, pswp) => {
            galleryInstance.pswp.on('change', () => {
              const currSlideElement = galleryInstance.pswp.currSlide.data.element;
              let captionHTML = '';
              if (currSlideElement) {
                const hiddenCaption = currSlideElement.querySelector('figcaption');
                if (hiddenCaption) {
                  captionHTML = hiddenCaption.innerHTML;
                }
              }
              el.innerHTML = captionHTML || '';
            });
          }
        });
    });

    galleryInstance.init()

    this.galleriesOnPage.push(galleryInstance);
  },

  initGalleriesOnPage() {
    const galleries = document.querySelectorAll('.js-imageGallery');
    galleries.forEach((gallery, index) => {
      this.initGalleryInstance(gallery)
    });
  },

  openGalleryByUrl(galleryId, imageId) {
    const galleryInstance = this.galleriesOnPage[galleryId];
    if (galleryInstance) {
      console.log(imageId);
      galleryInstance.loadAndOpen(imageId);
    } else {
      console.error(`Gallery with id ${galleryId} not found`);
    }
  },

  parseURLHash() {
    const hash = window.location.hash.substring(1);
    const params = {};

    if (hash.length < 5) {
      return params;
    }

    const vars = hash.split('&');
    vars.forEach((item) => {
      if (!item) return;

      const [key, value] = item.split('=');
      if (key && value) {
        params[key] = parseInt(value, 10);
      }
    });

    return params;
  },

  initURLHandling() {
    const hashData = this.parseURLHash();
    if (hashData.pid && hashData.gid) {
      this.openGalleryByUrl(hashData.gid - 1, hashData.pid);
    }
  },

  init() {
    this.initGalleriesOnPage();
    this.initURLHandling();
  },
};

export default imageGallery;
