<template>
  <div v-show="teamFirstname && teamLastname" :class="rootClass">
    <!-- Wrapper for Content-Builder -->
    <div class="m-contactPersonItem__wrapper">
      <!-- Telelephone, Mail and Address -->
      <div class="m-contactPersonItem__imageAndName">
        <div class="m-contactPersonItem__image">
          <div v-show="!teamImageSrc" class="m-contactPersonItem__imageFallback" />
          <img
            v-if="teamImageSrc"
            :src="teamImageSrc"
            :alt="`${teamFirstname} ${teamLastname}`"
            sizes="(min-width: 320px) 128px, 128px"
            class="m-contactPersonItem__imageVue lazyload"
          />
        </div>
        <div class="m-contactPersonItem__nameAndJob">
          <h4>
            <!-- eslint-disable-next-line -->
            <span class="m-contactPersonItem__noWrap">{{ teamHonoraryChurchTitle ? teamHonoraryChurchTitle + ' ' : '' }}{{ teamAcademicDegree ? teamAcademicDegree + ' ' : '' }}{{ teamFirstname }}</span> {{ teamLastname }} {{ teamOrdenskuerzel }}
          </h4>
          <p>{{ job }}</p>
          <!-- Telelephone, Mail and Address -->
          <div class="m-contactPersonItem__details">
            <span v-if="teamTelephone">
              Telefon:
              <a :href="`tel:${teamTelephone.replace('/\s|\-/g', '')}`">{{ teamTelephone }}</a>
              <br />
            </span>

            <span v-show="teamEmail">
              Mail: <a :href="`mailto:${teamEmail}`">{{ teamEmail }}</a> <br />
            </span>

            <span v-show="teamAddress"> {{ teamAddress }} <br /> </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useRootClass from '../../../src/composables/useRootClass';
import { computed } from 'vue';

interface IContactPersonItemProps {
  modifiers?: [];
  teamFirstname?: string;
  teamLastname?: string;
  teamJobDescription?: string;
  teamTelephone?: string;
  teamImageSrc?: string;
  teamEmail?: string;
  teamHonoraryChurchTitle?: string;
  teamAcademicDegree?: string;
  teamOrdenskuerzel?: string;
  teamRole?: string;
}

const props = withDefaults(defineProps<IContactPersonItemProps>(), {
  modifiers: () => [],
  teamFirstname: '',
  teamLastname: '',
  teamJobDescription: '',
  teamTelephone: '',
  teamImageSrc: '',
  teamEmail: '',
  teamHonoraryChurchTitle: '',
  teamAcademicDegree: '',
  teamOrdenskuerzel: '',
  teamRole: '',
});

const cn = 'm-contactPersonItem';
const { rootClass } = useRootClass({
  cn,
  modifiers: props.modifiers,
});

const job = computed(() => {
  return props.teamRole ?? props.teamJobDescription ?? null;
});
</script>
