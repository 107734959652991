<!-- eslint-disable -->
<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor"><g fill="none" stroke="currentColor" stroke-width="32"><path stroke-miterlimit="10" d="M248 64C146.39 64 64 146.39 64 248s82.39 184 184 184 184-82.39 184-184S349.61 64 248 64z"/><path stroke-linecap="round" stroke-linejoin="round" d="M220 220h32v116"/><path stroke-linecap="round" stroke-miterlimit="10" d="M208 340h88"/></g><path d="M248 130a26 26 0 1 0 26 26 26 26 0 0 0-26-26z"/></svg>
</template>
<!-- eslint-enable -->

<script>
export default {
  name: 'InfoIcon',
};
</script>
