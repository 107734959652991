/*
 * Description of what this file is for
 *
 * @package  baukasten
 * @author Martin Hellwagner
 * modalForm
 */

const modalForm = {
  cfg: {
    name: 'modalForm',
    selectors: {
      modalForm: '.o-modalForm',
      modalContainer: '.o-modalForm__container',
      modalCloseTriggerIcon: '.a-svgIcon--hamburger-x',
      input: '.a-input__input',
      textArea: '.a-textarea__textarea',
      remainingCharactersInfo: '.remainingCharactersInfo',
      checkbox: '.a-checkbox__checkbox',
      submitButton: '.a-button',
      mainContent: '.o-modalForm__mainContent',
    },
    el: {
      $root: undefined,
      $modalForms: undefined,
    },
    classNames: {
      error: 'has-error',
      warning: 'warning',
    },
  },
  storeModalForms() {
    this.cfg.el.$modalForms = document.querySelectorAll(this.cfg.selectors.modalForm);
  },
  initModalFormsBehavior() {
    if (this.cfg.el.$modalForms) {
      this.cfg.el.$modalForms.forEach((modalForm) => {
        const modalContainer = modalForm.querySelector(this.cfg.selectors.modalContainer);

        this.setModalHeight(modalContainer);
        this.initEventListeners(modalForm);
      });
    }
  },
  setModalHeight(modalContainer) {
    // Set window height in order to display modal correctly on mobile browsers
    modalContainer.style.maxHeight = `${window.innerHeight}px`;
  },
  initEventListeners(modalForm) {
    const textArea = modalForm.querySelector(this.cfg.selectors.textArea);
    const input = modalForm.querySelector(this.cfg.selectors.input);

    this.initRemainingTextIndicator(modalForm, textArea);
    this.initErrorHandlingBehavior(modalForm, textArea, input);
  },
  initRemainingTextIndicator(modalForm, textArea) {
    const remainingCharactersInfo = modalForm.querySelector(
      this.cfg.selectors.remainingCharactersInfo,
    );

    // Check how many characters are left
    const maxCharacters = textArea.getAttribute('maxlength');
    const maxCharacterWarningText =
      modalForm.dataset.characterWarningText || 'noch %amount% Zeichen';

    textArea.addEventListener('keyup', () => {
      remainingCharactersInfo.textContent = maxCharacterWarningText.replace(
        '%amount%',
        maxCharacters - textArea.value.length,
      );

      // Indicate when < 20% of possible characters are left
      if (maxCharacters / 5 >= maxCharacters - textArea.value.length) {
        if (!remainingCharactersInfo.classList.contains(this.cfg.classNames.warning)) {
          remainingCharactersInfo.classList.add(this.cfg.classNames.warning);
        }
      } else if (remainingCharactersInfo.classList.contains(this.cfg.classNames.warning)) {
        remainingCharactersInfo.classList.remove(this.cfg.classNames.warning);
      }
    });
  },
  initErrorHandlingBehavior(modalForm, textArea, input) {
    const form = modalForm.querySelector('form');
    const checkbox = form.querySelector(this.cfg.selectors.checkbox);
    const submitButton = form.querySelector(this.cfg.selectors.submitButton);

    // Remove errors
    input.addEventListener('click', () => {
      input.classList.remove(this.cfg.classNames.error);
    });

    textArea.addEventListener('click', () => {
      textArea.classList.remove(this.cfg.classNames.error);
    });

    checkbox.addEventListener('click', () => {
      checkbox.classList.remove(this.cfg.classNames.error);
    });

    // Handle validation
    submitButton.addEventListener('click', (e) => {
      // e.preventDefault();
      let errors = 0;

      if (input.hasAttribute('required') && input.value === '') {
        input.classList.add(this.cfg.classNames.error);
        errors += 1;
      }

      if (textArea.hasAttribute('required') && textArea.value === '') {
        textArea.classList.add(this.cfg.classNames.error); // Corrected
        errors += 1;
      }

      if (checkbox.hasAttribute('required') && !checkbox.checked) {
        checkbox.classList.add(this.cfg.classNames.error); // Corrected
        errors += 1;
      }

      // Submit form
      for (var [key, value] of new FormData(form).entries()) {
        console.log(key, value);
      }
      console.log(modalForm, form, errors);
      // if (errors === 0) modalForm.querySelector('form').submit();
    });
  },
  /**
   * Will manage one or multiple ModalForms on the same page.
   * Handles frontend error handling and validation.
   */
  init() {
    this.storeModalForms();
    this.initModalFormsBehavior();
  },
};

export default modalForm;
