<template>
  <div class="space-y-12">
    <div v-if="persons.length > 0" id="persons">
      <h6 class="-mb-2 font-sans uppercase text-gray text-xxs">Personen</h6>
      <div class="lg:flex lg:flex-wrap">
        <div v-for="hit in persons" :key="hit.teamEmail" class="w-full lg:w-1/2">
          <ContactPersonItem
            class="w-1/2"
            :modifiers="['white', 'multiple', 'search']"
            :team-firstname="hit.teamFirstname"
            :team-lastname="hit.teamLastname"
            :team-job-description="hit.teamJobDescription"
            :team-telephone="hit.teamTelephone"
            :team-image-src="hit.teamImageSrc"
            :team-email="hit.teamEmail"
            :team-address="hit.teamAddress"
            :team-honorary-church-title="hit.teamHonoraryChurchTitle"
            :team-academic-degree="hit.teamAcademicDegree"
            :team-ordenskuerzel="hit.teamOrdenskuerzel"
            :team-role="hit.teamRole"
          />
        </div>
      </div>
    </div>

    <div v-if="departmentsAndOrganisations.length > 0" id="departmentsAndOrganisations">
      <h6 class="mb-6 font-sans uppercase text-gray text-xxs">Referate und Organisationen</h6>

      <div class="grid grid-cols-1 gap-y-8 md:grid-cols-2">
        <div v-for="(item, index) in departmentsAndOrganisations" :key="index" class="col-span-1">
          <ContactSearchItem
            :title="item.title"
            :subtitle="item.subtitle"
            :email="item.teamEmail"
            :phone="item.teamTelephone"
            :website="item.website"
            :image="item.image"
          />
        </div>
      </div>
    </div>
    <div v-if="houses.length > 0" id="houses">
      <h6 class="mb-6 font-sans uppercase text-gray text-xxs">Häuser und Räume</h6>

      <div class="grid grid-cols-1 gap-y-8 lg:grid-cols-2">
        <div v-for="(item, index) in houses" :key="index" class="col-span-1">
          <ContactSearchItem
            :title="item.title"
            :subtitle="item.subTitle"
            :email="item.teamEmail"
            :phone="item.teamTelephone"
            :website="item.website"
            :image="item.image"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import ContactPersonItem from '../contactPersonItem/_component.vue';
import ContactSearchItem from './contactSearchItem.vue';

interface IWebsite {
  link: string;
  text: string;
}

interface IOrganisationOrDepartment {
  title: string;
  entryType: string;
  image: string;
  subtitle: string;
  locationStreet: string;
  locationZip: string;
  locationTown: string;
  teamTelephone: string;
  teamEmail: string;
  website: IWebsite;
}

interface IOrganisation extends IOrganisationOrDepartment {
  section: 'organisations';
}

interface IDepartment extends IOrganisationOrDepartment {
  section: 'houses';
}

interface IHouse {
  title: string;
  subTitle: string;
  image: string;
  section: 'departments';
  entryType: string;
  entryImage: string;
  locationStreet: string;
  locationZip: string;
  teamTelephone: string;
}

interface ITeam {
  section: 'team';
  entryType: string;
  teamFirstname: string;
  teamLastname: string;
  teamJobDescription: string;
  teamEmail: string;
  teamImageSrc: string;
  teamAddress: string;
  teamHonoraryChurchTitle: string;
  teamAcademicDegree: string;
  teamOrdenkuerzel: string;
  teamRole: string;
}

interface IProps {
  contacts: (IOrganisation | IHouse | IDepartment | ITeam)[];
}

const props = defineProps<IProps>();

const persons = computed(() => {
  return props.contacts.filter((contact) => contact.section == 'team');
});

const departmentsAndOrganisations = computed(() => {
  return props.contacts.filter((contact) => {
    const toFiler = ['organisations', 'departments'];
    return toFiler.includes(contact.section);
  });
});

const houses = computed(() => {
  return props.contacts.filter((contact) => contact.section == 'houses');
});
</script>
